import React from 'react'
import './Empaty.css'
export const Empaty = () => {
    return (
        <div className="mt-5 pt-5 ">
            <div className="container"> 
                <hr/>
                <div className="text-center">
                    <h2>¿Qué ofrecemos?</h2>
                </div>
                <div className="row margen justify-content-center">
                    <div className="col-md-5 text-center">
                        <div className="row align-items-center">
                            <div className="col">
                                <img className="icono" src={require("../img/4paciente.png")} alt="/" width="75%"/>
                                </div>
                            <div className="col">
                                Una aplicación con todas las herramientas necesarias para realizar tu sesiones y terapias a través de una videollamada.
                            </div>
                        </div>      
                    </div>
                    <div className="col-md-5 text-center">
                        <div className="row align-items-center">
                            <div className="col">
                                <img className="icono" src={require("../img/carpetas.png")} alt="/" width="75%"/>
                                </div>
                            <div className="col">
                                Podrás organizar, agendar y modificar sesiones de terapia grupal e individual, bajo tus criterios y de forma rápida y sencilla.
                            </div>
                        </div>      
                    </div>
                </div> 
                <div className="row margen justify-content-md-center">
                    <div className="col-md-5 text-center">
                        <div className="row align-items-center">
                            <div className="col">
                                <img className="icono" src={require("../img/notificacion.png")} alt="/" width="75%"/>
                                </div>
                            <div className="col">
                                Para tus pacientes, contamos con una aplicación móvil fácil de utilizar que les permitirá interactuar contigo, les recordará las sesiones que agendes.
                            </div>
                        </div>      
                    </div>
                    <div className="col-md-5 text-center">
                        <div className="row align-items-center">
                            <div className="col">
                                <img className="icono" src={require("../img/emo.png")} alt="/" width="75%"/>
                                </div>
                            <div className="col">
                            Los pacientes podrán expresar un pensamiento anónimo que nuestro asistente inteligente analizará para determinar su estado emocional e informártelo.
                            </div>
                        </div>      
                    </div>
                </div>
                <div className="row margen justify-content-md-center">
                    <div className="col-md-5 text-center">
                        <div className="row align-items-center">
                            <div className="col">
                                <img className="icono" src={require("../img/chat.png")} alt="/" width="75%"/>
                                </div>
                            <div className="col">
                                Podrás mantener un contacto directo y contínuo con tus pacientes a través de un chat privado, sin dar información confidencial tuya.
                            </div>
                        </div>      
                    </div>
                    <div className="col-md-5 text-center">
                        <div className="row align-items-center">
                            <div className="col">
                                <img className="icono" src={require("../img/grafico.png")} alt="/" width="75%"/>
                                </div>
                            <div className="col">
                                Podrás revisar el progreso de tus pacientes a través de gráficos fáciles de analizar.
                            </div>
                        </div>      
                    </div>
                </div>
            </div>
            <hr/>
        </div>
    )
}
