import React from 'react'
import './Inicio.css'

export const Inicio = () => {
    return (
        <div className="mt-5 pt-5">
            <div className="jumbotron">
                <div className="banner">
                    <img className="img-fluid banner" src={require('../img/empaty.png')} alt="logo-empaty"/>
                </div>
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <iframe title="pitch"  className="pr-3" width="560" height="315" src="https://www.youtube-nocookie.com/embed/SV55DHOus0k" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <div className="col-12 col-md-6" id="videollamadas"> 
                        <h3>¿Qué es Empaty? </h3>
                        <h6 className="text-muted text-justify">Empaty es una plataforma que le permitirá a psicólogas y psicólogos 
                            organizar su trabajo de forma online, mantener contacto con sus pacientes y agendar sesiones 
                            de trabajo ya sea en modalidad individual o grupal.
                        </h6>
                    </div>
                </div>
                <hr/>
                <div>
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <div className="card-body">
                                <h5 className="card-title">¿Por qué nosotros?</h5>
                                <h6 className="card-subtitle text-muted text-justify">Juntamos todas las 
                                herramientas que necesitas en un solo lugar.
                                <p>Nos integramos con Google Meets, una alternativa más segura que Zoom y cuyo plan 
                                gratuito incluye videoconferencias de mayor duración, para facilitar organización de videollamadas. 
                                Con nuestra aplicación, la distancia ya no se vuelve un problema en el cuidado de la salud mental.</p>
                                </h6>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card-body">
                                <img className="card-img-top card-img-top-profile" src={require('../img/videollamada2.png')} alt="car img"/>
                            </div>                
                        </div>
                    </div>
                    <hr/>
                    <div className="row align-items-top">
                        <div className="justify-content-between ml-2 col-md-5">
                            <h2 className="text-center">Noticias</h2>
                        </div>
                        <div className="justify-content-between ml-2 col-md-5">
                            <div className="fb-page" data-href="https://www.facebook.com/Empaty-113393623843145" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                                <blockquote cite="https://www.facebook.com/Empaty-113393623843145" className="fb-xfbml-parse-ignore">
                                    <a href="https://www.facebook.com/facebook">Facebook</a>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
