import React from 'react'
import './Footer.css'

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="row">
                <div className="col-md-4 col-12 align-self-top">
                    <div className="flex-row pt-3">
                        <a href="https://www.facebook.com/Empaty-113393623843145"><p className="link fa fa-facebook"></p></a>
                        <a href="https://www.instagram.com/empatyfsw"><p className="link fa fa-instagram"></p></a>
                    </div>
                </div>
                <div className="col-md-2 align-self-top">
                    <img src={require("../img/Logo_UTFSM.png")} alt="" width="150"/>
                </div>
                <div className="col-md-2 align-self-top">
                    <img src={require("../img/Gabej_ICONO_SVG.png")} alt="" width="250"/>
                </div>
                <div className="col-md-4 col-12 align-self-top pt-3">
                    <div className="row">
                        <div className="col text-right">
                            <img src={require('../img/iconoempaty.png')} alt="" width="100"/>
                        </div>
                        <div className="col text-center">
                            <address>
                                <p>Vicuña Mackenna 3939, San Joaquín, Región Metropolitana</p>
                                <p>empatyfsw2020@gmail.com</p>
                            </address>
                        </div>
                    </div>
                </div>
                
            </div>
        </footer>
    )
}
