import React from 'react'
import './Navbar.css'

export const Navbar = () => {
    return (
        <div className="mb-5">
            <nav className="navbar navbar-expand-md navbar-dark navbar-custom fixed-top">
                <a className="navbar-brand mr-auto" href="/">
                    <img src={require('../img/iconoempaty.png')} alt="" width="47"/>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto ml-3">
                        <li className="btn btn-style active"><a className="nav-link" href="/">Inicio</a></li>
                        <li className="btn btn-style active"><a className="nav-link" href="/info-empaty">Sobre Empaty</a></li>
                        <li className="btn btn-style active"><a className="nav-link" href="/info">Equipo</a></li>
                        <li className="btn btn-style active"><a className="nav-link" href="/contacto">Contacto</a></li>
                    </ul>
                </div>
                <a className="navbar-brand mr-auto" href="http://www.feriadesoftware.cl/">
                    <img src={require('../img/fesw-logo.png')} alt="" width="100"/>
                </a>
            </nav>
        </div>
    )
}
