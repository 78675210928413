import React from 'react'
import './Contacto.css'

export const Contacto = () => {
    return (
        <div className="contenedor text-center">
            <div className="row">
                <div className="col-md-6 col-12">
                    <h2>Contacto</h2>
                    <div className="row margen align-items-center">
                        <div className="col-md-4 text-right">
                            <h3>Dirección</h3>
                        </div>
                        <div className="col-md-8 text-left pt-2">
                            <p>Vicuña Mackenna 3939, San Joaquín, Región Metropolitana</p>
                        </div>
                    </div>
                    <div className="row margen align-items-center">
                        <div className="col-md-4 text-right">
                            <h3>Correo</h3>
                        </div>
                        <div className="col-md-8 text-left pt-2">
                            <p>empatyfsw2020@gmail.com</p>
                        </div>
                    </div>
                    <div className="row margen align-items-center">
                        <div className="col-md-4 text-right pt-3">
                            <h3>Redes sociales</h3>
                        </div>
                        <div className="col-md-4 text-left">
                            <div className="col-sm-4 d-flex flex-row">
                                <div className="red-social fb align-self-center mr-1">
                                    <a href="https://www.facebook.com/Empaty-113393623843145">
                                        <p className="link fa fa-facebook pl-1"></p>
                                    </a>
                                </div>
                                <div className="red-social ig align-self-center">
                                    <a href="https://www.instagram.com/empatyfsw">
                                        <p className="link fa fa-instagram pl-1"></p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col12">
                    <iframe title="contacto" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3327.311017290386!2d-70.62003644470518!3d-33.49328439475121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662d006fa56ee51%3A0x81dacfbe20db68cf!2sUniversidad%20T%C3%A9cnica%20Federico%20Santa%20Mar%C3%ADa!5e0!3m2!1ses-419!2scl!4v1600713070118!5m2!1ses-419!2scl" 
                    width="600" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                </div>
            </div>
            
        </div>
    )
}