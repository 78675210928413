//librerias react
import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

//nuestras views
import { Footer } from './components/Footer';
import { Navbar } from './components/Navbar';
import { Inicio } from './components/Inicio';
import { Informacion } from './components/Informacion';
import { Contacto } from './components/Contacto';
import { Empaty } from './components/Empaty';
import GA from './components/GoogleAnalytics'

function App() {
  return (
    <Router>
      { GA.init() && <GA.RouteTracker /> }
      <Navbar/>
      <div className="container-fluid">
        <Switch>
          <Route path='/info-empaty' component={Empaty}/>
          <Route path="/contacto" component={Contacto} />
          <Route path="/info" component={Informacion} />
          <Route path="/" component={Inicio}/>
        </Switch>
      </div>
      <Footer/>
    </Router>
    
  );
}

export default App;
