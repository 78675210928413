import React from 'react'
import './Informacion.css'

export const Informacion = () => {
    return (
        <div>
            <div className="container pt-5">
                <div className="row justify-content-center pt-5">
                    <div className="col-md-4 col-12 align-self-center">
                        <div className="card-body">
                            <img className="card-img-top" src={require('../img/Logos_Empresa_Oficial.png')} alt="car img" height="85px"/>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 text-justify pt-5">
                        <h6>GaBeJ Softwares es una startup chilena con el objetivo de desarrollar aplicaciones que 
                            faciliten a los trabajadores independientes a insertarse en el mundo del teletrabajo.</h6>
                    </div>
                </div>
                <hr/>
                <div className="row justify-content-center">
                <div className="justify-content-between col-md-5">
                            <div className="card-body">
                                <h5 className="card-title">Misión</h5>
                                <h6 className="card-subtitle text-muted text-justify">Buscamos facilitar el trabajo de profesionales 
                                independientes y, además, facilitar la conectividad con sus clientes. Esto mediante el desarrollo de aplicaciones
                                innovadoras que utilicen las TIC donde todas las herramientas para organizar y realizar su trabajo estén a su 
                                disposición.
                                </h6>
                            </div>
                        </div>
                        <div className="justify-content-between col-md-5">
                            <div className="card-body">
                                <h5 className="card-title">Visión</h5>
                                <h6 className="card-subtitle text-muted text-justify">Ser una pre-empresa destacada en el aporte de
                                soluciones tecnológicas para ayudar a los trabajadores independientes en la realización de sus trabajos y
                                en la inserción al mundo del teletrabajo.
                                </h6>
                            </div>
                        </div>
                </div>
            </div>
            <div className="pt-5">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-3 mb-4 mr-5 d-flex justify-content-center">
                        <div className="card border-0 shadow">
                            <img src={require('../img/enzi.jpg')} className="card-img-top card-img-top-profile" alt="..."/>
                            <div className="card-body text-center">
                                <h5 className="card-title mb-0">Gabriel Ortega</h5>
                                <div className="card-text text-black-50">Jefe de Proyecto y Desarrollador Backend</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4 mr-5 d-flex justify-content-center">
                        <div className="card border-0 shadow">
                            <img src={require('../img/jara.jpg')} className="card-img-top card-img-top-profile" alt="..."/>
                            <div className="card-body text-center">
                                <h5 className="card-title mb-0">Sebastián Jara</h5>
                                <div className="card-text text-black-50">Jefe Q.A. y Desarrollador Frontend</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4 mr-5 d-flex justify-content-center">
                        <div className="card border-0 shadow">
                            <img src={require('../img/benja.jpg')} className="card-img-top card-img-top-profile" alt="..."/>
                            <div className="card-body text-center">
                                <h5 className="card-title mb-0">Benjamín Riquelme</h5>
                                <div className="card-text text-black-50">Jefe T.I. y Desarrollador Backend</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4 mr-5 d-flex justify-content-center">
                        <div className="card border-0 shadow">
                            <img src={require('../img/gabriel.jpg')} className="card-img-top card-img-top-profile" alt="..."/>
                            <div className="card-body text-center">
                                <h5 className="card-title mb-0">Gabriel Araya</h5>
                                <div className="card-text text-black-50">Desarrollador Fullstack</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4 d-flex justify-content-center">
                        <div className="card border-0 shadow">
                            <img src={require('../img/seider.jpg')} className="card-img-top card-img-top-profile" alt="..."/>
                            <div className="card-body text-center">
                                <h5 className="card-title mb-0">Benjamín Seider</h5>
                                <div className="card-text text-black-50">Desarrollador Frontend</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
